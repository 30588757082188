<!-- 水电管理系统入口页文件 -->
<template>
    <div id="app">
      <div class="container">
        <div class="header" style="height: 80px;">
          <div class="title">
            <div class="item"></div>
            <!-- 标题 -->
            <div class="item">智慧用电监控</div>
            <div class="item"> {{ times }} </div>
          </div>
          <!-- 顶部导航栏 -->
          <div class="menu">
            <span v-for="item in childrenTitle" :key="item.path" @click="pageToogle(item)" :style="item.name == clickName ? 'color:#ffd04b' : ''">{{ item.title }}</span>
          </div>
          <!-- 返回按钮 -->
          <span class="iconfont icon-iotechfanhui icon" @click="$router.push('/select')"></span>
        </div>
        <div class="main">
          <router-view></router-view>
        </div>
    </div>
  </div></template>
  
  <script>  
  export default {
    components: {
      
    },
  
    data() {
      return {
        times:'',  // 时间显示
        childrenTitle:[],  //子菜单的路由
        clickName:'',  //获取当前所在页面
        
      }
    },
  
    created() {
        this.settingTime() //设置时间
        this.getChildRouters() // 获取子路由
    },
  
    mounted() {
      if (this.$route.path == '/ElectricityDataOverview') {
        this.clickName = 'ElectricityDataOverview'
    }
    },
  
    methods: {
        // 设置时间
        settingTime(){
            // 设置初始时间
            this.times = this.$moment().format("YYYY-MM-DD HH:mm:ss")
            // 设置定时器
            this.timer = setInterval(() => {
                this.times = this.$moment().format("YYYY-MM-DD HH:mm:ss")
            }, 1000);
        },
        // 获取子路由
        getChildRouters(){
          let routerList = this.$router.options.routes;
          console.log(routerList);
          for(let i = 0;i<routerList.length;i++){
            if (routerList[i].name === "ElectricityMonitoringEntry"){
                this.childrenTitle=routerList[i].children
            }
          }
          console.log(this.childrenTitle);
        },
        // 页面跳转
        pageToogle(item){
          this.clickName = item.name
          this.$router.push(item.path)
        },
    },
    destroyed() {
        //销毁定时器 
        clearInterval(this.timer);
    },
  
  }
  </script>
  
  <style scoped lang="scss">
  @font-face {
    font-family: "led regular";
    src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
  }
  
  .menu {
    position: absolute;
    top: 30px;
    left: 20px;
  
    span {
      color: rgb(42, 248, 248);
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      padding-left: 20px;
      font-size: 22px;
      font-family: "宋体";
      margin: 0 20px;
      padding: 5px 10px;
    }
  }
  
  .container {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background: url("../../../assets/images/dptBG.jpg") no-repeat;
    background-size: 100% 100%;
  
    .header {
      z-index: 99;
      position: absolute;
      top: 0;
      width: 100%;
      height: 80px;
      background: url("../../../assets/images/header.png") no-repeat;
      background-size: 100% 160%;
      .icon {
        position: absolute;
        top: 0;
        right: 40px;
        font-size: 25px;
        line-height: 80px;
        color: aqua;
        cursor: pointer;
      }
  
      .icon:hover {
        color: aquamarine;
      }
  
      .title {
        width: 100%;
        height: 80px;
        display: flex;
  
        .item:nth-child(1) {
          height: 80px;
          width: 35%;
          text-align: center;
          line-height: 80px;
          font-size: 30px;
          color: rgb(42, 248, 248);
          letter-spacing: 4px;
          font-family: "宋体";
          cursor: pointer;
          user-select: none;
        }
  
        .item:nth-child(2) {
          height: 80px;
          width: 30%;
          line-height: 80px;
          font-family: "宋体";
          font-size: 36px;
          color: aqua;
          font-weight: 600;
          text-align: center;
        }
  
        .item:nth-child(3) {
          height: 80px;
          width: 35%;
          text-align: center;
          line-height: 80px;
          font-size: 34px;
          color: rgb(42, 248, 248);
          letter-spacing: 4px;
          font-family: "led regular";
        }
      }
    }
    // .content {
    //   box-sizing: border-box;
    //   width: 100%;
    //   height: 90%;
  
    //   .line1 {
    //     box-sizing: border-box;
    //     padding-top: 10px;
    //     width: 100%;
    //     height: 50%;
    //     display: flex;
  
    //     .left {
    //       width: 50%;
    //       box-sizing: border-box;
    //       height: 100%;
    //       padding: 20px;
    //     }
  
    //     .right {
    //       box-sizing: border-box;
    //       width: 50%;
  
    //       .slot {
    //         padding: 5px;
    //         color: rgba(212, 239, 250, 1);
    //         user-select: none;
  
    //         input {
    //           border: none;
    //           outline: none;
    //           background: rgba(5, 110, 151, 0.3);
    //           color: rgba(212, 239, 250, 1);
    //         }
  
    //         .icon {
    //           padding: 0 5px;
    //           cursor: pointer;
    //           color: rgba(5, 110, 151, 0.8);
    //         }
    //       }
    //     }
    //   }
  
    //   .line2 {
    //     margin-top: 10px;
    //     box-sizing: border-box;
    //     width: 100%;
    //     height: 50%;
    //     display: flex;
  
    //     .left {
    //       width: 100%;
    //       height: 100%;
    //     }
  
    //   }
  
    //   .overview-top {
    //     box-sizing: border-box;
    //     width: 100%;
    //     height: 50%;
    //     display: flex;
  
    //     .chart1 {
    //       box-sizing: border-box;
    //       background: rgba(0, 0, 0, 0.1);
    //       margin: 5px;
    //       border-radius: 10px;
    //       width: 50%;
    //     }
  
    //     .chart2 {
    //       box-sizing: border-box;
    //       background: rgba(0, 0, 0, 0.1);
    //       margin: 5px;
    //       border-radius: 10px;
    //       width: 50%;
    //     }
    //   }
  
    //   .overview-data {
    //     box-sizing: border-box;
    //     height: 50%;
    //     width: 100%;
    //     display: flex;
  
    //     .chart1 {
    //       box-sizing: border-box;
    //       background: rgba(0, 0, 0, 0.1);
    //       margin: 5px;
    //       border-radius: 10px;
    //       width: 50%;
    //     }
  
    //     .chart2 {
    //       box-sizing: border-box;
    //       background: rgba(0, 0, 0, 0.1);
    //       margin: 5px;
    //       border-radius: 10px;
    //       width: 50%;
    //     }
    //   }
    // }

    .main{
      width: 100%;
      height: 91%;
      padding-top: 80px;
    }
  }

  
  </style>